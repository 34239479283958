.data-analysis .container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.data-analysis .data-processing-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 400px;
}

.ant-card-head-title {
    font-size: 40px;
    padding: 0;
}

.data-analysis .card .ant-card-body {
    padding: 5px 40px 40px 40px;
}

.data-analysis .card .ant-card-body::before,
.data-analysis .card .ant-card-body::after {
    display: none;
}


.data-analysis .card>div {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.data-analysis .table {
    overflow: auto;
    max-height: 400px;
}

.data-analysis .table-external-heading {
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: .5rem;
    cursor: pointer;
    font-size: 20px;
    user-select: none;
}

.data-analysis .table th {
    font-weight: bold !important;
    white-space: nowrap !important;
}

.data-analysis .table td {
    white-space: nowrap !important;
}

.data-analysis .table tr {
    white-space: nowrap !important;
}

.data-analysis .table-first-col {
    white-space: nowrap !important;
}