.metadata-uploading .container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.ant-card-head-title {
    font-size: 40px;
    padding: 0;
}

.metadata-uploading .card .ant-card-body {
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.metadata-uploading .card .ant-card-body::before,
.metadata-uploading .card .ant-card-body::after {
    display: none;
}


.metadata-uploading .card>div {
    display: flex;
    flex-direction: column;
    gap: 30px;
}